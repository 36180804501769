const icons = [
  {
    link: "https://github.com/Wubaboo",
    classes: "fa-brands fa-github fa-2xl",
    alt: "GitHub",
  },
  {
    link: "mailto:ei2pai@uwaterloo.ca",
    classes: "fa-solid fa-envelope fa-2xl",
    alt: "Email",
  },
  {
    link: "https://www.linkedin.com/in/evan-pai/",
    classes: "fa-brands fa-linkedin fa-2xl",
    alt: "LinkedIn",
  },

  {
    link: "https://www.goodreads.com/user/show/87804703-evan",
    classes: "fa-solid fa-book fa-2xl",
    alt: "Goodreads",
  },
];

export default icons;
