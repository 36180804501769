const images = [
  {
    bold: "Placid:",
    caption: " pleasantly calm or peaceful",
    path: process.env.PUBLIC_URL + "/assets/img/placid.png",
  },
  {
    bold: "Evan | Sara:",
    caption: " names of some people",
    path: process.env.PUBLIC_URL + "/assets/img/es.jpeg",
  },
  {
    bold: "Ataraxy:",
    caption: " a state of serene calmness; free from anxiety",
    path: process.env.PUBLIC_URL + "/assets/img/ataraxy.png",
  },
  {
    bold: "Amelia | Preston:",
    caption: "names of two cousins",
    path: process.env.PUBLIC_URL + "/assets/img/tai.jpeg",
  },
  {
    bold: "Johnson | Lakshay:",
    caption: " names of two friends",
    path: process.env.PUBLIC_URL + "/assets/img/jl.jpeg",
  },
];

export default images;
